import { useState } from "react";
import { Gallery } from "react-grid-gallery";
import useInfiniteScroll from 'react-infinite-scroll-hook';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { HashLoader } from "react-spinners";
import { useLoadImages, Image } from "../hooks/useLoadImages";

export interface ScrollerProps {
}

export default function Scroller(props: ScrollerProps) {
    const [index, setIndex] = useState(-1);
    const { loading, images, hasMore, loadMore } = useLoadImages();

    const [sentryRef] = useInfiniteScroll({
      loading,
      hasNextPage: hasMore,
      onLoadMore: loadMore,
      rootMargin: '0px 0px 800px 0px',
    });

    const handleClick = (index: number, item: Image) => setIndex(index);

    return (
        <>
            <Lightbox
                slides={images}
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
            />
            <Gallery
                images={images}
                onClick={handleClick}
                defaultContainerWidth={600}
                enableImageSelection={false}
                rowHeight={500}
            />
            <HashLoader
                color="#ffffff"
                loading={hasMore}
                cssOverride={{ margin: '100px auto'}}
            />
            <div ref={sentryRef} />
        </>
    )
}