import Scroller from './components/scroller';
import './App.css';

function App() {
  return (
    <div className="App">
      <Scroller />
    </div>
  );
}

export default App;
