const apiUrl = 'https://d8wbkrpw1szda.cloudfront.net/api/load';

export async function load(token?: string) {
    const url = new URL(apiUrl);

    if (token) {
        url.searchParams.append('token', token);
    }

    const response = await fetch(url);
    const result = await response.json();

    if (response.status !== 200) {
        throw new Error(response.status.toString());
    }

    return result;
}