import { useState } from "react";
import { load } from "../util/api";

export interface Image {
    src: string;
    width: number;
    height: number;
}

export const useLoadImages = () => {
    const [images, setImages] = useState<Image[]>([])
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [nextToken, setNext] = useState<string>();

    const loadMore = async () => {
        setLoading(true);
        const result = await load(nextToken);

        const newImages = result.images.map((img: any) => ({ src: img.source, width: img.width, height: img.height }));

        setImages([...images, ...newImages]);
        setHasMore(!!result.nextToken);
        setNext(result.nextToken);
        setLoading(false);
    }


    return { loading, images, hasMore, loadMore };
}